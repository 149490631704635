const template = document.createElement('template');

template.innerHTML = `
  <form class="pure-form pure-form-stacked" id='container'>
    <div id='content'>
      <div id='business-data'>
        <div id='header'>
          <div id='title'>Inserisci i dati per la registrazione se non hai già un account</div>
        </div>
        <div class='definer'>Nome:
          <div id='nome'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Cognome:
          <div id='cognome'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Email: 
          <div id='email'>
            <input type='email'>
          </div>
        </div>
        <div class='definer'>Password
          <div id='password'>
            <input type='password'>
          </div>
        </div>
        <div class='definer'>Ripeti Password
          <div id='ripeti-password'>
            <input type='password'>
          </div>
        </div>
        <div id='header'>
          <div id='title'>Inserisci i dati di fatturazione</div>
        </div>
        <div class='definer'>Citta
          <div id='citta'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Via
          <div id='via'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>CAP
          <div id='CAP'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Provincia
          <div id='provincia'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Ragione Sociale:
          <div id='ragione-sociale'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Codifce Fiscale Fatturazione:
          <div id='cod-fiscale-fatturazione'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Partita IVA:
          <div id='partita-iva'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>PEC Email:
          <div id='pec-email'>
            <input type='email'>
          </div>
        </div>
        <div class='definer'>Codice Fattura Elettronica:
          <div id='cod-fattura-elettronica'>
            <input type='text'>
          </div>
        </div>
      </div>
    </div>
    <div id='error-footer'>
      Errori
    </div>
    <div id='footer'>
      <button id='register'>Registrati</button>
    </div>
  </form>

  <style>
    ${require('../../css/purecss/base.pure.css')}
    ${require('../../css/purecss/grids.pure.css')}
    ${require('../../css/purecss/grids-responsive.pure.css')}
    ${require('../../css/purecss/tables.pure.css')}
    ${require('../../css/purecss/forms.pure.css')}
    ${require('../../css/purecss/forms-nr.pure.css')}

    * {
      box-sizing: border-box;
    }
    :host {
      height: 100%;
      width: 100%;
      display: block;
    }
    #container {
      border-radius: 7px;
      height: 80%;
      width: 80%;
      margin-top: 5%;
      margin-left: 10%;
      margin-bottom: 5%;
      background: white;
    }
    #business-data #header {
      height: 40px;
      margin-bottom: 20px;
    }
    #business-data {
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    #personal-data #header {
      height: 40px;
      margin-bottom: 20px;
    }
    #personal-data {
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    #header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      height: 10%;
      border-bottom: 2px solid #eee;
    }
    #content {
      width: 100%;
      height: 80%;
      border-bottom: 2px solid #eee;
    }
    #close {
      border-radius: 55px;
    }
    #close:hover {
      background-color: #eee;
      cursor: pointer;
    }
    #footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10%;
    }
    #error-footer {
      display: none;
      align-items: center;
      justify-content: center;
      height: 10%;
      background: red;
      color: white;
    }
    #error-footer.show {
      display: flex;
    }
    #footer button {
      cursor: pointer;
      border-radius: 7px;
      width: 100%;
      height: 100%;
    }
`;

class UGDCECSignup extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});
    this.onRegistratiClick = this.onRegistratiClick.bind(this);
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domBtnRegistrati = this.shadowRoot.querySelector('#register');

    this.domNome = this.shadowRoot.querySelector('#nome input');
    this.domCognome = this.shadowRoot.querySelector('#cognome input');
    this.domEmail = this.shadowRoot.querySelector('#email input');
    this.domPassword = this.shadowRoot.querySelector('#password input');
    this.domRipetiPassword = this.shadowRoot.querySelector('#ripeti-password input');

    this.domCitta = this.shadowRoot.querySelector('#citta input');
    this.domCAP = this.shadowRoot.querySelector('#CAP input');
    this.domVia = this.shadowRoot.querySelector('#via input');
    this.domProvincia = this.shadowRoot.querySelector('#provincia input');
    this.domRagioneSociale = this.shadowRoot.querySelector('#ragione-sociale input');
    this.domCodFiscaleFatturazione = this.shadowRoot.querySelector('#cod-fiscale-fatturazione input');
    this.domPartitaIVA = this.shadowRoot.querySelector('#partita-iva input');
    this.domPecEmail = this.shadowRoot.querySelector('#pec-email input');
    this.domCodFatturaElettronica = this.shadowRoot.querySelector('#cod-fattura-elettronica input');

    this.domErrorFooter = this.shadowRoot.querySelector('#error-footer');

    this.addEventListeners();
  }

  addEventListeners() {
    this.domBtnRegistrati.addEventListener('click', this.onRegistratiClick);
  }

  validateInput() {
    const isValidated = this.domNome.value && this.domCognome.value && this.domEmail.value && this.domPassword.value && this.domRipetiPassword.value && this.domRagioneSociale.value && this.domCodFiscaleFatturazione.value && this.domPartitaIVA.value && this.domPecEmail && this.domCodFatturaElettronica;
    return isValidated && this.domPassword.value === this.domRipetiPassword.value;
  }

  async onRegistratiClick(evt) {
    evt.preventDefault();
    const ragioneSociale = this.domRagioneSociale.value;
    const codiceFiscaleFatturazione = this.domCodFiscaleFatturazione.value;
    const partitaIVA = this.domPartitaIVA.value;
    const pecEmail = this.domPecEmail.value;
    const codiceFatturaElettronica = this.domCodFatturaElettronica.value;

    if (this.validateInput()) {
      if (this.domErrorFooter.classList.contains('show')) {
        this.domErrorFooter.classList.remove('show');
      }

      try {
        await firebase.auth().createUserWithEmailAndPassword(this.domEmail.value, this.domPassword.value);

        await fetch(window.url + '/setUserInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.domEmail.value,
            ragioneSociale,
            codiceFiscaleFatturazione,
            partitaIVA,
            pecEmail,
            codiceFatturaElettronica,
            nome: this.domNome.value.slice(0, 1).toUpperCase() + this.domNome.value.slice(1, this.domNome.value.length),
            cognome: this.domCognome.value.slice(0, 1).toUpperCase() + this.domCognome.value.slice(1, this.domCognome.value.length),
            via: this.domVia.value,
            provincia: this.domProvincia.value,
            citta: this.domCitta.value,
            cap: this.domCAP.value,
          }),
        });
      } catch (ex) {
        this.domErrorFooter.classList.add('show');
      }
    } else {
      this.domErrorFooter.classList.add('show');
    }
  }
}

customElements.define('ugdcec-signup', UGDCECSignup);
