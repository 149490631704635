const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <div id="card-corso">
      <div id='header'>
        <div id='nome' class='nome-corso'></div>
        <div id='costo'></div>
        <div id='subscribe' class='btn-user-iscriviti'>ISCRIVITI</div>
      </div>
      <p id='scadenza'></p>
      <div id='desc'></div>
    </div>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      height: 100%;
      display: block;
    }
    #container {
      font-size: 1.5em;
      height: 100%;
    }
    #header:after {
      content: "";
      display: table;
      clear: both;
    }
    #card-corso #header .nome-corso {
      font-weight: bold;
      float: left;
    }
    #card-corso #header .btn-user-iscriviti {
      font-weight: bold;
      float: right;
      cursor: pointer;
      background: #1976d2;
      color: white;
      font-size: 1em;
      border-radius: 5px;
      padding: .5em;
    }
    #card-corso {
      background: white;
      display: block;
      padding: .8em;
      height: 100%;
      margin: 0 auto;
      width: 100%;
      border-radius: 5px;
    }
    #header {
      width: 100%;
      padding: .4em;
      border-bottom: 1px solid #eee;
    }
    footer {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
    #costo {
      
    }
  </style>
`;

class InfoCorso extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onSubscribeClick = this.onSubscribeClick.bind(this);
    window.addEventListener('resize', this.onResize.bind(this));
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domNome = this.shadowRoot.querySelector('#nome');
    this.domDescription = this.shadowRoot.querySelector('#desc');
    this.domBtnSubscribe = this.shadowRoot.querySelector('#subscribe');
    this.domScadenza = this.shadowRoot.querySelector('#scadenza');

    this.domBtnSubscribe.addEventListener('click', this.onSubscribeClick);
  }

  removeButtonIscriviti() {
    this.domBtnSubscribe.parentElement.removeChild(this.domBtnSubscribe);
  }

  onResize(evt) {
    evt.preventDefault();
    if (this.out) {
      this.translateOut();
    }
  }

  onSubscribeClick() {
    this.dispatchEvent(
        new CustomEvent('subscribe-clicked', {bubbles: true, detail: this})
    );
  }

  translateOut() {
    this.out = true;
    this.gbcr = this.getBoundingClientRect();

    this.style.transform = `translateX(-${this.gbcr.width}px)`;
  }

  translateIn() {
    this.out = false;
    this.gbcr = this.getBoundingClientRect();

    this.style.transform = `none`;
  }

  set nome(nome) {
    this.setAttribute('nome', nome);

    this.domNome.textContent = nome;
  }

  get nome() {
    return this.getAttribute('nome');
  }

  set desc(desc) {
    this.setAttribute('desc', desc);

    this.domDescription.textContent = desc;
  }

  get desc() {
    return this.getAttribute('desc');
  }

  set scadenza(scadenza) {
    this.setAttribute('scadenza', scadenza);

    this.domScadenza.textContent = scadenza;
  }

  get scadenza() {
    return this.getAttribute('scadenza');
  }
}

customElements.define('info-corso', InfoCorso);
