import UGDCECCorsi from './ce/ugdcec-corsi.js';

const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <ugdcec-header></ugdcec-header>
    <ugdcec-login></ugdcec-login>
    <ugdcec-signup></ugdcec-signup>
    <iscriviti-unione></iscriviti-unione>
  </div>

  <style>
    iscriviti-unione {
      position: fixed;
      bottom: 1em;
      left: 1em;
      width:  10em;
      height: 6em;
      font-size: 1.3em;
      transition: box-shadow .3s ease;
      z-index: 13;
    }
    iscriviti-unione:hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  </style>
`;

export default class UGDCECApp extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.onUserLoggedInHandler = this.onUserLoggedInHandler.bind(this);
    this.onIscrivitiUnioneClose = this.onIscrivitiUnioneClose.bind(this);
  }

  connectedCallback() {
    this.domContainer = this.shadowRoot.querySelector('#container');
    this.domUgdcecLogin = this.shadowRoot.querySelector('ugdcec-login');
    this.domUgdcecHeader = this.shadowRoot.querySelector('ugdcec-header');
    this.domIscrivitiUnione = this.shadowRoot.querySelector('iscriviti-unione');

    this.domIscrivitiUnione.addEventListener('close', this.onIscrivitiUnioneClose);
    this.domUgdcecLogin.addEventListener('user-logged-in', this.onUserLoggedInHandler);
  }

  onIscrivitiUnioneClose(evt) {
    this.domContainer.removeChild(evt.target);
    localStorage.setItem('closed', true);
  }

  onUserLoggedInHandler(evt) {
    this.domUgdcecHeader.userLogged = evt.detail.isLogged;

    if (evt.detail.isLogged) {
      this.domUgdcecLogin.style.display = 'none';
      this.shadowRoot.appendChild(new UGDCECCorsi());
    } else {
      // Show Login Page
    }
  }
}

customElements.define('ugdcec-app', UGDCECApp);
