import UGDCECCorso from './ugdcec-corso.js';
import UserSubscriptionAccept from './user-subscription-accept.js';
import UGDCECSpinner from './ugdcec-spinner.js';

const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    #no-corsi {
      width: calc(100% - 2em);
      margin: 1em;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: box-shadow .3s ease, opacity .4s ease;
      border-radius: 5px;
      background: white;
      text-align: center;
      padding: 10px;
    }
    ugdcec-corso {
      width: calc(100% - 2em);
      margin: 1em;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      transition: box-shadow .3s ease, opacity .4s ease;
      border-radius: 5px;
    }
    ugdcec-corso:hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
  </style>
`;

export default class UGDCECCorsi extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.corsi = [];

    this.onSubscribeClickedHandler = this.onSubscribeClickedHandler.bind(this);
    this.onBackButtonHandler = this.onBackButtonHandler.bind(this);
    this.onProponiUnioneHandler = this.onProponiUnioneHandler.bind(this);
    this.onIscrittoUnioneClickedHandler = this.onIscrittoUnioneClickedHandler.bind(this);
  }

  async initCorsi() {
    const {default: UGDCECSpinner} = await import('./ugdcec-spinner.js');
    this.domContainer.appendChild(new UGDCECSpinner());
    const response = await fetch(window.url + '/getCorsi');
    this.domContainer.innerHTML = '';
    const corsi = await response.json();

    if (corsi.length > 0) {
      for (const corso of corsi) {
        this.corsi.push(this.domContainer.appendChild(new UGDCECCorso(corso)));
      }
      this.domContainer.addEventListener('subscribe-clicked', this.onSubscribeClickedHandler);
      this.domContainer.addEventListener('iscritto-unione-clicked', this.onIscrittoUnioneClickedHandler);
      this.domContainer.addEventListener('back', this.onBackButtonHandler);
      this.domContainer.addEventListener('proponi-unione', this.onProponiUnioneHandler);
    } else {
      this.domContainer.innerHTML = `<div id='no-corsi'> Non ci sono corsi sottoiscrivibili </div>`;
    }
  }

  async connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domContainer = this.shadowRoot.querySelector('#container');

    await this.initCorsi();
  }

  onBackButtonHandler(evt) {
    const corso = evt.detail;

    this.restoreOtherCorsi();

    if (corso.parentElement) {
      corso.parentElement.removeChild(this.userSubscriptionAccept);
    }
  }

  onProponiUnioneHandler() {
    this.userSubscriptionAccept.iscrizioneUnione = true;
  }

  restoreOtherCorsi() {
    this.corsi.forEach((c) => (c.hide ? (c.hide = false) : ''));
  }

  removeOtherCorsi(corso){
    this.corsi.forEach((c) => {
      if (c !== corso) {
        c.hide = true;
      }
    });
  }

  async appendUserSubscriptionAccept(corso, bIscrittoUnione) {
    const currentUser = firebase.auth().currentUser;
    const ugdcecSpinner = corso.parentElement.appendChild(new UGDCECSpinner());
    const dati = await (await fetch(window.url + '/getUserInfo')).json();
    corso.parentElement.removeChild(ugdcecSpinner);

    /* Creating User Subscription Accpet and appending it to the DOM */
    this.userSubscriptionAccept = new UserSubscriptionAccept();
    this.userSubscriptionAccept = corso.parentElement.appendChild(this.userSubscriptionAccept);

    if(corso.costoNonIscritti)  {
      if(bIscrittoUnione) {
        this.userSubscriptionAccept.costoCorso = corso.costo;
      } else {
        this.userSubscriptionAccept.costoCorso = corso.costoNonIscritti;
      }
    }

    /** Setting User and Event Data */
    this.userSubscriptionAccept.idCorso = corso.id;
    this.userSubscriptionAccept.nomeCorso = corso.name;
    this.userSubscriptionAccept.userName = dati.ragioneSociale;
    this.userSubscriptionAccept.userEmail = currentUser.email;
    this.userSubscriptionAccept.pecEmail = dati.pecEmail;
    this.userSubscriptionAccept.tassabile = corso.tassabile || false;
    this.userSubscriptionAccept.bIscrittoUnione = bIscrittoUnione;

    this.userSubscriptionAccept.addEventListener('subscribed', () => {
      corso.parentElement.removeChild(this.userSubscriptionAccept);
      this.domContainer.removeChild(corso);
      this.onBackButtonHandler({detail: corso});
      window.alert('L\'iscrizione è stata confermata');
    });
  }

  async onSubscribeClickedHandler(evt) {
    const corso = evt.detail;
    this.removeOtherCorsi(corso);
  }

  async onIscrittoUnioneClickedHandler(evt) {
    const {corso, bIscrittoUnione} = evt.detail;
    this.appendUserSubscriptionAccept(corso, bIscrittoUnione);
  }
}

customElements.define('ugdcec-corsi', UGDCECCorsi);
