const template = document.createElement('template');

template.innerHTML = `
  <form id='container' class="pure-form pure-form-stacked">
      <fieldset>
          <legend>Form di accesso</legend>

          <label for="email">Email</label>
          <input class='input-corrections' id="email" type="email" placeholder="Email">
          <span class="input-corrections pure-form-message">Campo richiesto.</span>

          <label for="password">Password</label>
          <input class='input-corrections' id="password" type="password" placeholder="Password">
          <span class="input-corrections pure-form-message">Campo richiesto.</span>
          
          <button type="submit" id='login' class="pure-button pure-button-primary">Login</button>
      </fieldset>
  </form>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      width: 100%;
    }

    ${require('../../css/purecss/base.pure.css')}
    ${require('../../css/purecss/grids.pure.css')}
    ${require('../../css/purecss/grids-responsive.pure.css')}
    ${require('../../css/purecss/tables.pure.css')}
    ${require('../../css/purecss/forms.pure.css')}
    ${require('../../css/purecss/forms-nr.pure.css')}

    .input-corrections {
      margin: 0 auto !important;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }
    form {
      width: calc(100% - 66%);
      margin: 0 auto;
      padding: 1em;
      text-align: center;
      margin-top: 1em;
      background: white;
    }
  </style>
`;
class UGDCECLogin extends HTMLElement {
  /*
    ${require('../../css/purecss/forms-nr.pure.css')}
    ${require('../../css/purecss/forms.pure.css')}
    ${require('../../css/purecss/grids.pure.css')}
    ${require('../../css/purecss/tables.pure.css')}
    ${require('../../css/purecss/grids-responsive.pure.css')}
  */

  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onLoginClick = this.onLoginClick.bind(this);
    this.onAuthStateChanged = this.onAuthStateChanged.bind(this);
    this.checkUserStatus = this.checkUserStatus.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    this.isLogged = false;
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domApp = document.body.querySelector('ugdcec-app');
    this.domUgdcecHeader = document
        .querySelector('body > ugdcec-app')
        .shadowRoot.querySelector('#container > ugdcec-header');
    this.domBtnLogin = this.shadowRoot.querySelector('#login');
    this.domEmail = this.shadowRoot.querySelector('#email');
    this.domPassword = this.shadowRoot.querySelector('#password');
    this.domRegister = document
        .querySelector('body > ugdcec-app')
        .shadowRoot.querySelector('#container > ugdcec-signup');
    this.domBtnLogin.addEventListener('click', this.onLoginClick);
    document.addEventListener('logout', this.onLogout);

    firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
  }

  onAuthStateChanged(user) {
    this.checkUserStatus(user);
  }

  set isLogged(bool) {
    if (bool) {
      const oldFetch = fetch;
      window.fetch = async (url, options) => {
        let newOptions = {};

        if (options && options.headers) {
          if (firebase && firebase.auth().currentUser) {
            options.headers['Authorization'] = 'Bearer ' + await firebase.auth().currentUser.getIdToken();
          }
          newOptions = options;
        } else {
          newOptions.headers = {};
          if (firebase && firebase.auth().currentUser) {
            newOptions.headers['Authorization'] = 'Bearer ' + await firebase.auth().currentUser.getIdToken();
          }
        }

        return await oldFetch(url, newOptions);
      };
      const userLoggedIn = new CustomEvent('user-logged-in', {detail: {isLogged: bool}});
      this.dispatchEvent(userLoggedIn);
    } else {
      if (window.location.href !== '/') {
      }
    }
  }

  async checkUserStatus(user = firebase.auth().currentUser) {
    if (user) {
      this.isLogged = true;

      this.domUgdcecHeader.addLogoutButton();
      this.domUgdcecHeader.addProfileButton();
      this.domRegister.parentElement.removeChild(this.domRegister);
    } else {
      this.isLogged = false;
    }
  }

  async onLoginClick(evt) {
    evt.preventDefault();
    const email = this.domEmail.value;
    const password = this.domPassword.value;
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (ex) {
      window.alert('Credenziali errate, riprovare');
    }
  }
}

customElements.define('ugdcec-login', UGDCECLogin);
