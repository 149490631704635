const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <div id="card-corso">
      <div id='info'>
        Gentile <div id='username'> Utente Utente </div>,<br>
        si sta iscrivendo a <div id='nome-corso'></div>
        <p id='iscrizione-unione' class='hide'>e all'unione</p>
        che costa <div id='costo'></div>€.
        <p id='resoconto'>
        Verrà inviata una copia di cortesia all'indirizzo PEC: <div id='pec-email'></div></p>
        <div id='choose-if-subscribed'>
          
        </div>
      </div>
      <div id='header'>
        <div id='segui-corso' class="btn-user-iscriviti">
          Segui il corso
        </div>
      </div>
    </div>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    .input input {
      margin: .3em !important;
    }
    .box {
      margin: 1em;
    }
    #container {
      width: calc(100% - 2em);
      margin: .7em -1em 1em 1em;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      border-radius: 5px;
      font-size: 1.5em;
      background: white;
      height: 100%;
    }
    #container:hover {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    :host {
      transition: box-shadow .3s ease, opacity .4s ease;
      height: 100%;
      opacity: 0;
      display: block;
    }
    #card-corso {
      background: white;
      display: block;
      padding: .8em;
      height: 100%;
      margin: 0 auto;
      width: 100%;
      border-radius: 5px;
    }
    .btn-user-iscriviti {
      font-weight: bold;
      cursor: pointer;
      background: #1976d2;
      color: white;
      font-size: 1em;
      margin: 0 auto;
      text-align: center;
      margin-top: 0.3em;
      width: calc(100% - 20em);
      border-radius: 5px;
      padding: .5em;
    }
    
    #usermail {
      display: inline;
    }
    #info div {
      display: inline;
      font-weight: bold;
    }
    .hide {
      display: none;
    }
  </style>
`;

export default class UserSubscriptionAccept extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onSeguiCorsoClick = this.onSeguiCorsoClick.bind(this);

    this.bIscrittoUnione = false;
  }

  get pecEmail() {
    return this.getAttribute('pec');
  }
  set nomeCorso(nomeCorso) {
    this.setAttribute('nome-corso', nomeCorso);
    this.domNomeCorso.textContent = nomeCorso;
  }
  set costoCorso(costoCorso) {
    this.setAttribute('costo-corso', costoCorso);
    this.domCosto.textContent = costoCorso;
  }
  set userName(username) {
    this.setAttribute('nome-corso', username);
    this.domUsername.textContent = username;
  }
  set pecEmail(pec) {
    this.setAttribute('pec', pec);
    this.domPECEmail.textContent = pec;
  }
  set tassabile(bool) {
    this.setAttribute('tassabile', bool);
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domCardCorso = this.shadowRoot.querySelector('#card-corso');
    this.domNomeCorso = this.shadowRoot.querySelector('#nome-corso');
    this.domCosto = this.shadowRoot.querySelector('#costo');
    this.domUsername = this.shadowRoot.querySelector('#username');
    this.domIscrizioneUnione = this.shadowRoot.querySelector('#iscrizione-unione');
    this.domBtnAccettaCorso = this.shadowRoot.querySelector('#segui-corso');
    this.domPECEmail = this.shadowRoot.querySelector('#pec-email');

    this.hide = false;

    this.domBtnAccettaCorso.addEventListener('click', this.onSeguiCorsoClick);
  }

  async onSeguiCorsoClick(evt) {
    const spinner = this.domCardCorso.appendChild(document.createElement('ugdcec-spinner'));

    this.domCardCorso.removeChild(spinner);

    const response = window.confirm('Confermare l\'iscrizione?');

    if (response) {
      await fetch(window.url + '/subscribe?idCorso=' + this.idCorso + '&iscrittoUnione=' + this.bIscrittoUnione);
      this.dispatchEvent(new CustomEvent('subscribed', {bubbles: true}));
    } else {
      window.alert('L\'iscrizione è stata annullata');
    }
  }

  set hide(bool) {
    this.setAttribute('hide', bool);
    if (bool) {
      this.style.opacity = 0;

      this.addEventListener('transitionend', () => {
        if (bool) this.style.display = 'none';
      }, {once: true});
    } else {
      this.style.display = 'block';

      requestAnimationFrame(() => {
        this.style.opacity = 1;
      });
    }
  }
}

customElements.define('user-subscription-accept', UserSubscriptionAccept);
