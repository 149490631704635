const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <div id='header'>
      <img src='${require('../../icons/left.svg')}'>
      <div id='title'>
        Ti stai iscrivendo al corso: <div id='name'></div>
      </div>
      <div>
        Per controllare i propri dati di fatturazione cliccare su "Profilo" in alto a destra.
      </div>
    </div>
    <div id='content'>
      <p id='question'>Sei iscritto/a all'Unione?</p>
      <div class='answers' id='yes'>Si</div>
      <div class='answers' id='no'>No</div>
      <div id='positive'>Iscritto all'Unione</div>
      <div id='negative'>Non iscritto all'Unione</div>
    </div>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      height: 100%;
      display: block;
    }
    #container {
      height: 100%;
      font-size: 1.5em;
      background: white;
    }
    #header {
      width: 96%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-left: 2%;
      margin-right: 2%;
      border-bottom: 2px solid #eee;
    }
    #header img {
      border-radius: 55px;
      width: 5%;
      transition: background-color .3s ease;
    }
    #header img:hover {
      cursor: pointer;
      background-color: #eee;
    }
    #title {
      width: 95%;
    }
    #title, #name {
      display: inline-block;
    }
    #title {
      padding: .8em;
      border-left: 1px solid black;
      width: 85%;
      margin-top: 1%;
      margin-bottom: 1%;
      height: 90%;
    }

    #name {
      font-weight: bold;
    }

    .hide {
      display: none;
    }

    #content {
      height: 100px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    #content .answers {
      padding: 10px;
      border-radius: 5px;
      width: 100px;
      text-align: center;
      color: white;
      cursor: pointer;
    }
    
    #content #yes{
      background: blue;
    }

    #content #no{
      background: red;
    }

    #positive, #negative {
      color: black;
      display: none;
    }
  </style>
`;

class IscrizioneCorso extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onBtnGoBackHandler = this.onBtnGoBackHandler.bind(this);
    this.onBtnIscrittoUnioneClicked = this.onBtnIscrittoUnioneClicked.bind(this);

    window.addEventListener('resize', this.onResize.bind(this));
  }

  /**
   * Used to resize correctly the length of 'iscrizione-corso' element
   * @param {ResizeEvent} evt
   */
  onResize(evt) {
    evt.preventDefault();
    if (this.in) {
      this.translateIn();
    }
  }

  /**
   * Called when the element is positioned inside the DOM
   */
  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.btnGoBack = this.shadowRoot.querySelector('#header img');
    this.domNomeCorso = this.shadowRoot.querySelector('#name');
    this.domIscrittoQuestion = this.shadowRoot.querySelector('#question');
    this.domBtnYes = this.shadowRoot.querySelector('#yes');
    this.domBtnNo = this.shadowRoot.querySelector('#no');
    this.domPositive = this.shadowRoot.querySelector('#positive');
    this.domNegative = this.shadowRoot.querySelector('#negative');

    this.domBtnYes.addEventListener('click', this.onBtnIscrittoUnioneClicked);
    this.domBtnNo.addEventListener('click', this.onBtnIscrittoUnioneClicked);
    this.btnGoBack.addEventListener('click', this.onBtnGoBackHandler);
  }

  /**
   * Name Setter
   * @param {String} name
   */
  set name(name) {
    this.domNomeCorso.textContent = name;

    this.setAttribute('name', name);
  }

  get name() {
    return this.getAttribute('name');
  }

  translateIn() {
    this.in = true;
    this.gbcr = this.getBoundingClientRect();

    this.style.transform = `translateX(-${this.gbcr.width}px)`;
  }

  translateOut() {
    this.in = false;
    this.gbcr = this.getBoundingClientRect();

    this.style.transform = `none`;
  }

  onBtnGoBackHandler() {
    this.dispatchEvent(new CustomEvent('back', {bubbles: true}));
  }

  onBtnProponiUnioneClick() {
    this.dispatchEvent(new CustomEvent('proponi-unione', {bubbles: true}));
  }

  restoreQuestion() {
    [this.domIscrittoQuestion, this.domBtnYes, this.domBtnNo].map((el) => {
      el.style.display = 'block';
    });

    [this.domNegative, this.domPositive].map((el) => {
      el.style.display = 'none';
    });
  }

  onBtnIscrittoUnioneClicked(event) {
    const bIscrittoUnione = event.target.textContent === 'Si';

    this.dispatchEvent(new CustomEvent('iscritto-unione-clicked', {bubbles: true, detail: bIscrittoUnione}));

    [this.domIscrittoQuestion, this.domBtnYes, this.domBtnNo].map((el) => {
      el.style.display = 'none';
    });

    if (bIscrittoUnione) {
      this.domPositive.style.display = 'block';
    } else {
      this.domNegative.style.display = 'block';
    }
  }
}

customElements.define('iscrizione-corso', IscrizioneCorso);

export default IscrizioneCorso;
