

/* Import Firebase */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
window.firebase = firebase;

// Initialize
import firebaseConfig from './firebaseConfig.json';
firebase.initializeApp(firebaseConfig);

import './css/index.css';

/* CSS IMPORTS */


//import AdminHeader from './js/ce/admin-header.js';
import './js/ce/admin-header.js';
import './js/ce/info-corso.js';
import './js/ce/iscrizione-corso.js';
import './js/ce/profile-viewer.js';
import './js/ce/settings-viewer.js';
import './js/ce/ugdcec-corsi.js';
import './js/ce/ugdcec-corso.js';
import './js/ce/ugdcec-header.js';
import './js/ce/ugdcec-login.js';
import './js/ce/ugdcec-signup.js';
import './js/ce/user-subscription-accept.js';
import UGDCECApp from './js/ugdcec-app.js';


document.addEventListener('DOMContentLoaded', () => {
  //window.url = 'http://localhost:5000';
  window.url = process.env.API_URL;
  document.body.appendChild(new UGDCECApp());
});
