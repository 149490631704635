const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    Admin
  </div>

  <style>
    * {
      box-sizing: border-box;
      user-select: none;
    }
    #container {
      background: #eee;
      width: 100%;
      padding: 0.5em;
      border-radius: 5px;
      transition: all .4s ease;
      cursor: pointer;  
      text-align: center;
      margin: 0.2em;
    }
    #container:active {
      background-color: black;
      color: white;
    }
    #container:hover {
      transform: scale(1.1);
    }
  </style>
`;

export default class AdminHeader extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});
    this.onBtnManageClick = this.onBtnManageClick.bind(this);
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domBtnManage = this.shadowRoot.querySelector('#container');

    this.domBtnManage.addEventListener('click', this.onBtnManageClick);
  }

  onBtnManageClick() {
    const settingsViewer = document.createElement('settings-viewer');

    document.body.appendChild(settingsViewer);
  }
}

customElements.define('admin-header', AdminHeader);
