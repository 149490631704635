import UGDCECSpinner from './ugdcec-spinner';

const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <div id='header'>
      <div id='title'>Profilo</div> <div id='close'> <img style='transform: rotate(45deg);' src='${require('../../icons/add.svg')}'> </div>
    </div>
    <div id='content'>
      <div id='personal-data'>
        <div id='header'>
          <div id='title'>Dati Personali</div>
        </div>
        <div class='definer'>Nome: <div id='name'></div></div>
        <div class='definer'>Cognome: <div id='surname'></div></div>
        <div class='definer'>Email: <div id='email'></div></div>
      </div>
      <div id='business-data'>
        <div id='header'>
          <div id='title'>Dati di Fatturazione</div>
        </div>
        <div class='definer'>Citta
          <div id='citta'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Via
          <div id='via'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>CAP
          <div id='CAP'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Provincia
          <div id='provincia'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Ragione Sociale:
          <div id='ragione-sociale'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Codifce Fiscale Fatturazione:
          <div id='cod-fiscale-fatturazione'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Partita IVA:
          <div id='partita-iva'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>PEC Email:
          <div id='pec-email'>
            <input type='email'>
          </div>
        </div>
        <div class='definer'>Codice Fattura Elettronica:
          <div id='cod-fattura-elettronica'>
            <input type='text'>
          </div>
        </div>
      </div>
    </div>
    <div id='footer'>
      <button id='salva'>Salva</button>
    </div>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      width: 100%;
      display: block;
    }
    #container {
      border-radius: 7px;
      height: 80%;
      width: 80%;
      margin-top: 5%;
      margin-left: 10%;
      margin-bottom: 5%;
      background: white;
      box-shadow: 0 0 0 999px rgba(0,0,0,0.8);
    }
    #business-data #header {
      height: 40px;
      margin-bottom: 20px;
    }
    #business-data {
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    #personal-data #header {
      height: 40px;
      margin-bottom: 20px;
    }
    #personal-data {
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
    }
    #header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      height: 10%;
      border-bottom: 2px solid #eee;
    }
    #content {
      overflow-y: auto;
      width: 100%;
      height: 80%;
      border-bottom: 2px solid #eee;
    }
    #close {
      border-radius: 55px;
    }
    #close:hover {
      background-color: #eee;
      cursor: pointer;
    }
    .definer {
      font-weight: bold;
      height: 30px;
    }
    .definer div {
      float: right;
      display: inline-block;
      font-weight: initial;
      width: 50%;
    }
    .definer div input{
      width: 100%;
      border: none;
      border-bottom: 2px solid #eee;
      outline: none;
    }
    .definer div input:focus {
      border-bottom: 2px solid black;
    }
    #footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10%;
    }
    #footer button {
      cursor: pointer;
      border-radius: 7px;
      width: 100%;
      height: 100%;
    }
  </style>
`;

export default class ProfileViewer extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onClose = this.onClose.bind(this);
    this.onSalvaClick = this.onSalvaClick.bind(this);
  }

  async connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domBtnClose = this.shadowRoot.querySelector('#close');
    this.domBtnSalva = this.shadowRoot.querySelector('#salva');

    this.domNome = this.shadowRoot.querySelector('#name');
    this.domCognome = this.shadowRoot.querySelector('#surname');
    this.domEmail = this.shadowRoot.querySelector('#email');

    this.domCitta = this.shadowRoot.querySelector('#citta input');
    this.domCAP = this.shadowRoot.querySelector('#CAP input');
    this.domVia = this.shadowRoot.querySelector('#via input');
    this.domProvincia = this.shadowRoot.querySelector('#provincia input');
    this.domRagioneSociale = this.shadowRoot.querySelector('#ragione-sociale input');
    this.domCodFiscaleFatturazione = this.shadowRoot.querySelector('#cod-fiscale-fatturazione input');
    this.domPartitaIVA = this.shadowRoot.querySelector('#partita-iva input');
    this.domPecEmail = this.shadowRoot.querySelector('#pec-email input');
    this.domCodFatturaElettronica = this.shadowRoot.querySelector('#cod-fattura-elettronica input');
    this.domHeader = this.domBtnClose.parentElement;
    this.addEventListeners();
    await this.popolate();
  }

  async popolate() {
    const spinner = this.domHeader.appendChild(new UGDCECSpinner());
    let data = localStorage.getItem('userinfo');


    if (!data) {
      try {
        data = await (await fetch(window.url + '/getUserInfo')).json();
        localStorage.setItem('userinfo', JSON.stringify(data));
      } catch (ex) {
        console.error(ex);
        window.alert('Non è stato possibile caricare i dati dell\'utente, ricaricare la pagina');
        return;
      }
    } else {
      data = JSON.parse(data);
    }

    this.domHeader.removeChild(spinner);

    this.domNome.textContent = data.nome;
    this.domCognome.textContent = data.cognome;
    this.domEmail.textContent = firebase.auth().currentUser.email;
    this.domRagioneSociale.value = data.ragioneSociale;
    this.domCodFatturaElettronica.value = data.codiceFatturaElettronica;
    this.domPartitaIVA.value = data.partitaIVA;
    this.domPecEmail.value = data.pecEmail;
    this.domCodFiscaleFatturazione.value = data.codiceFiscaleFatturazione;
    this.domCitta.value = data.citta;
    this.domCAP.value = data.cap;
    this.domVia.value = data.via;
    this.domProvincia.value = data.provincia;
  }

  addEventListeners() {
    this.domBtnClose.addEventListener('click', this.onClose);
    this.domBtnSalva.addEventListener('click', this.onSalvaClick);
  }

  async onSalvaClick() {
    const ragioneSociale = this.domRagioneSociale.value;
    const codiceFiscaleFatturazione = this.domCodFiscaleFatturazione.value;
    const partitaIVA = this.domPartitaIVA.value;
    const pecEmail = this.domPecEmail.value;
    const codiceFatturaElettronica = this.domCodFatturaElettronica.value;
    const citta = this.domCitta.value;
    const via = this.domVia.value;
    const cap = this.domCAP.value;
    const provincia = this.domProvincia.value;

    await fetch(window.url + '/updateUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ragioneSociale,
        codiceFiscaleFatturazione,
        partitaIVA,
        pecEmail,
        codiceFatturaElettronica,
        citta,
        via,
        cap,
        provincia,
      }),
    });

    const userinfo = await (await fetch(window.url + '/getUserInfo')).json();
    localStorage.setItem('userinfo', JSON.stringify(userinfo));

    this.onClose();
  }

  onClose() {
    document.body.removeChild(this);
  }
}

customElements.define('profile-viewer', ProfileViewer);
