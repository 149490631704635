import ProfileViewer from './profile-viewer.js';
import AdminHeader from './admin-header.js';

const template = document.createElement('template');

template.innerHTML = `
  <div>
    <nav>
      <div id='title'>Sistema Iscrizioni UGDCEC</div>
    </nav>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    
    nav > * {
      width: 10%;
      cursor: pointer;
    }
    #title {
      width: 70%;
      height: 100%;
      font-weight: bold;
      font-size: 2em;
    }
    nav {
      padding: .8em;
      display: flex;
      align-items: center;
      background: white;
      height: 4em;
      width: 100%;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
    nav i {
      padding: 16px;
      float: right;
      border-radius: 55px;
      transition: background-color .3s ease;
    }
    nav i:hover{
      background-color: #eee;
      cursor: pointer;
    }
    #logout, #profile {
      background: #eee;
      padding: 0.5em;
      border-radius: 5px;
      transition: all .4s ease;
      cursor: pointer;
      text-align: center;
      margin: 0.2em;
    }
    #logout:hover, #profile:hover {
      transform: scale(1.1);
    }
  </style>
`;

class UGDCECHeader extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onBtnProfileClick = this.onBtnProfileClick.bind(this);
  }

  connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domProfileButton = null;
    this.domNav = this.shadowRoot.querySelector('nav');
    this.domLogoutButton = null;
  }

  onBtnProfileClick(evt) {
    const profileViewer = new ProfileViewer();

    document.body.appendChild(profileViewer);
  }

  addLogoutButton() {
    const div = document.createElement('div');

    div.innerHTML = '<div id=\'logout\'> Logout </div>';
    this.domProfileButton = this.domNav.appendChild(div);

    this.domProfileButton.addEventListener('click', this.onBtnLogoutClick);
  }

  addProfileButton() {
    const div = document.createElement('div');

    div.innerHTML = '<div id=\'profile\'> Profilo </div>';
    this.domLogoutButton = this.domNav.appendChild(div);

    this.domLogoutButton.addEventListener('click', this.onBtnProfileClick);
  }

  async onBtnLogoutClick() {
    await firebase.auth().signOut();
    window.location.href = '/';
  }

  async isUserAdmin() {
    const tokenResult = await firebase.auth().currentUser.getIdTokenResult();

    this.isAdmin = tokenResult.claims.admin;
    return this.isAdmin;
  }

  applyAdminElements() {
    const adminHeader = new AdminHeader();

    this.domNav.appendChild(adminHeader);
  }

  set userLogged(bool) {
    if (bool) {
      this.setAttribute('user-logged', bool);
      this.isUserAdmin().then((b) => {
        if (b) {
          this.applyAdminElements();
        }
      }).catch(console.error);
    } else {
      this.removeAttribute('user-logged');
    }
  }

  get userLogged() {
    return this.getAttribute('user-logged');
  }
}

customElements.define('ugdcec-header', UGDCECHeader);
