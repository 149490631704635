import UGDCECSpinner from './ugdcec-spinner.js';

const template = document.createElement('template');

template.innerHTML = `
  <div id='container'>
    <div id='header'>
      <div id='title'>Lista dei Corsi</div>
      <div id='create-corso'> <img src='${require('../../icons/add.svg')}'> Crea Nuovo Corso </div>
      <div id='create-corso' class='create-corso-unione'> <img src='${require('../../icons/add.svg')}'> Crea Corso non tassabile </div>
      <div id='close'> <img style='transform: rotate(45deg);' src='${require('../../icons/add.svg')}'> </div>
    </div>
    <div id='content'>
      <div id='sidebar'>
      </div>
      <div id='manage-corso' class='hide'>
        <div id='header'>
          <div id='title'>Gestisci Corso</div>
        </div>
        <div class='definer'>Nome Corso: 
          <div id='nome'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Descrizione:
          <div id='descrizione'>
            <input type='text'>
          </div>
        </div>
        <div class='definer'>Scadenza:
          <div id='scadenza'>
            <input type='date'>
          </div>
        </div>
        <div class='definer'>Costo: 
          <div id='costo'>
            <input type='number'>
          </div>
        </div>
        <div class='definer'>Costo per non iscritti:
          <div id='costo-non-iscritti'>
            <input type='number'>
          </div>
        </div>
        <div id='crea-corso-footer'>
          <button id='salva'>Salva Corso</button>
        </div>
        
        <div id='view-iscritti' class='hide'>
          <div id='header'>
            <div id='title'>Lista Iscritti</div>
          </div>
          <table>
            <tr>
              <th> Nome </th>
              <th> Cognome </th>
              <th> Email </th>
            </tr>
          </table>
      </div>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      height: 100%;
      width: 100%;
      display: block;
    }
    #no-corsi{
      background: #eee;
      color: black;
      margin: 2px;
      text-align: center;
    }
    #container {
      border-radius: 7px;
      height: 80%;
      width: 80%;
      margin-top: 5%;
      margin-left: 10%;
      margin-bottom: 5%;
      background: white;
      box-shadow: 0 0 0 999px rgba(0,0,0,0.8);
    }
    #manage-corso #header {
      height: 40px;
      margin-bottom: 20px;
    }
    #manage-corso {
      width: 80%;
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      float: right;
    }
    #header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      height: 10%;
      border-bottom: 2px solid #eee;
    }
    #content {
      overflow-y: scroll;
      width: 100%;
      height: 80%;
      border-bottom: 2px solid #eee;
    }
    #close {
      border-radius: 55px;
    }
    #close:hover {
      background-color: #eee;
      cursor: pointer;
    }
    .definer {
      font-weight: bold;
      height: 30px;
    }
    .definer div {
      display: inline-block;
      font-weight: initial;
    }
    .definer div {
      float: right;
    }
    #crea-corso-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10%;
    }
    #crea-corso-footer button {
      cursor: pointer;
      border-radius: 7px;
      padding: 10px;
    }
    #sidebar {
      width: 20%;
      height: 100%;
      border-right: 2px solid #eee;
      float: left;
    }
    #create-corso {
      display: flex;
      cursor: pointer;
      font-size: 18px;
      padding: 10px;
      /* justify-content: space-around; */
      align-items: center;
      /* justify-items: center; */
      /* align-content: center; */
      justify-content: space-between;
    }
    #create-corso:hover {
      background: #eee;
      transform: background .3s ease;
    }
    #sidebar div {
      display: flex;
      justify-content: space-around;
      cursor: pointer;
      font-size: 18px;
      padding: 10px;
    }
    #sidebar div #delete:hover {
      background: white;
      border-radius: 55px;
    }
    #sidebar div:hover {
      background: #eee;
      transform: background .3s ease;
    }
    #view-iscritti {
      border-bottom: 2px solid #eee;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      float: right;
    }
    #view-iscritti #header {
      height: 40px;
    }
    #view-iscritti table {
      width: 100%;
      padding-top: 10px;
    }
    tr {
      text-align: center;
    }
    td img {
      border-radius: 55px;
      cursor: pointer;
    }
    td img:hover {
      background-color: #eee;
    }
    .hide {
      display: none;
    }
    .selected {
      background: #eee;
    }
  </style>
`;

class SettingsViewer extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({mode: 'open'});

    this.onClose = this.onClose.bind(this);
    this.onSalvaClick = this.onSalvaClick.bind(this);
    this.onBtnDeleteListener = this.onBtnDeleteListener.bind(this);
    this.onCorsoClickedListener = this.onCorsoClickedListener.bind(this);
    this.onBtnCreateCorsoListener = this.onBtnCreateCorsoListener.bind(this);
    this.onBtnCreateCorsoUnioneListener = this.onBtnCreateCorsoUnioneListener.bind(this);

    this.mutexSaveCorso = false;
  }

  async onBtnCreateCorsoUnioneListener() {
    this.removeSelectedCorso();
    this.clearGestisciCorso();

    this.domBtnSalva.removeAttribute('tassabile');

    this.domManageCorso.classList.remove('hide');
    if (!this.domListaIscritti.classList.contains('hide')) {
      this.domListaIscritti.classList.add('hide');
    }
  }
  async onBtnDeleteListener(evt) {
    evt.preventDefault();

    const target = evt.target;
    const idCorso = target.getAttribute('id-corso');

    switch (target.getAttribute('id')) {
      case 'edit':
        const corsoDoc = this.getCorsoInfo(idCorso);

        await this.popolateGestisciCorso(corsoDoc);

        break;
    }
  }

  clearGestisciCorso() {
    this.domNomeCorso.value = '';
    this.domDescrizione.value = '';
    // this.domScadenza.value = corsoDoc.scadenza.toDate(); // to transform timestamp
    this.domCosto.value = '';
    this.domCostoNonIscritti.value = '';
  }

  async clearListaIscritti() {
    this.domListaIscrittiTable.innerHTML = `
    <tr>
      <th> Nome </th>
      <th> Cognome </th>
      <th> Email </th>
    </tr>`;
  }

  async popolateGestisciCorso(corsoDoc) {
    this.domNomeCorso.value = corsoDoc.nome;
    this.domDescrizione.value = corsoDoc.desc;
    const szDate = corsoDoc.scadenza;
    this.domScadenza.value = `${szDate.slice(szDate.length - 4, szDate.length)}-${szDate.slice(szDate.length - 7, 5)}-${szDate.slice(szDate.length - 10, 2)}`;
    this.domCosto.value = corsoDoc.costo;
    this.domCostoNonIscritti.value = corsoDoc.costoNonIscritti;

    console.log(corsoDoc);

    if (corsoDoc.proponiIscrizioneUnione) {
      this.domProponiIscrizioneUnione.checked = true;
    }
  }

  getCorsoInfo(idCorso) {
    return this.corsi.find((c) => c.id == idCorso);
  }

  async connectedCallback() {
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    this.domListaIscritti = this.shadowRoot.querySelector('#view-iscritti');
    this.domListaIscrittiTable = this.shadowRoot.querySelector('#view-iscritti table');
    this.domManageCorso = this.shadowRoot.querySelector('#manage-corso');
    this.domBtnClose = this.shadowRoot.querySelector('#close');
    this.domBtnSalva = this.shadowRoot.querySelector('#salva');
    this.domNomeCorso = this.shadowRoot.querySelector('#nome input');
    this.domDescrizione = this.shadowRoot.querySelector('#descrizione input');
    this.domScadenza = this.shadowRoot.querySelector('#scadenza input');
    this.domCosto = this.shadowRoot.querySelector('#costo input');
    this.domCostoNonIscritti = this.shadowRoot.querySelector('#costo-non-iscritti input');
    this.domListaCorsi = this.shadowRoot.querySelector('#sidebar');
    this.domBtnCreateCorso = this.shadowRoot.querySelector('#create-corso');
    this.domBtnCreateCorsoUnione = this.shadowRoot.querySelector('.create-corso-unione');

    this.addEventListeners();
    await this.viewCorsi();
  }

  async onCorsoClickedListener(event) {
    let idCorso = event.target.getAttribute('id-corso');
    const toDelete = event.target.getAttribute('id');

    if (toDelete == 'delete') {
      idCorso = event.target.parentElement.getAttribute('id-corso');
    }

    if (idCorso) {
      this.removeSelectedCorso();
      if (toDelete) {
        try {
          const response = confirm('Cancellare il corso?');
          if (response) {
            await fetch(window.url + '/deleteCorso?idCorso=' + idCorso);
          }
          window.alert('Corso cancellato');
          window.location.reload();
        } catch (ex) {
          console.error(ex);
          window.alert('C\'è stato un problema durante l\'eliminazione');
        }
        this.domListaCorsi.removeChild(event.target.parentElement);
      } else {
        event.target.classList.add('selected');
        this.domManageCorso.classList.remove('hide');
        this.domListaIscritti.classList.remove('hide');

        this.domBtnSalva.setAttribute('id-corso', idCorso);
        const corsoDoc = this.getCorsoInfo(idCorso);

        Promise.all([this.clearListaIscritti(), this.popolateGestisciCorso(corsoDoc), this.popolateListaIscritti(idCorso)]);
      }
    }
  }

  removeSelectedCorso() {
    const selectedElements = this.domListaCorsi.querySelectorAll('.selected');

    selectedElements && selectedElements.forEach( (element) => {
      element.classList.remove('selected');
    });
  }

  async onBtnCreateCorsoListener() {
    this.domBtnSalva.removeAttribute('id-corso');
    this.domBtnSalva.setAttribute('tassabile', true);

    this.removeSelectedCorso();
    this.clearGestisciCorso();

    this.domManageCorso.classList.remove('hide');
    if (!this.domListaIscritti.classList.contains('hide')) {
      this.domListaIscritti.classList.add('hide');
    }
  }

  addEventListeners() {
    this.domBtnClose.addEventListener('click', this.onClose);
    this.domBtnSalva.addEventListener('click', this.onSalvaClick);
    this.domBtnCreateCorso.addEventListener('click', this.onBtnCreateCorsoListener);
    this.domListaCorsi.addEventListener('click', this.onCorsoClickedListener);
    this.domBtnCreateCorsoUnione.addEventListener('click', this.onBtnCreateCorsoUnioneListener);
  }

  async onSalvaClick() {
    if (!this.mutexSaveCorso) {
      this.mutexSaveCorso = true;
      const body = {
        nome: this.domNomeCorso.value,
        desc: this.domDescrizione.value,
        scadenza: this.domScadenza.value,
        costo: this.domCosto.value,
        costoNonIscritti: this.domCostoNonIscritti.value,
      };

      if (this.domBtnSalva.hasAttribute('id-corso')) {
        body.idCorso = this.domBtnSalva.getAttribute('id-corso');
        this.domBtnSalva.removeAttribute('id-corso');
      }

      body.tassabile = this.domBtnSalva.hasAttribute('tassabile');
      this.domBtnSalva.removeAttribute('tassabile');

      await fetch(window.url + '/setCorso', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      this.onClose();
      window.location.reload();
    } else {
      window.alert('Il corso sta per essere salvato!');
    }

    this.mutexSaveCorso = false;
  }

  onClose() {
    document.body.removeChild(this);
  }

  async viewCorsi() {
    const spinner = this.domListaCorsi.appendChild(new UGDCECSpinner());

    try {
      const response = await fetch(window.url + '/getCorsi?all');
      this.domListaCorsi.removeChild(spinner);
      this.corsi = await response.json();


      if (this.corsi.length > 0) {
        for (const corso of this.corsi) {
          const divCorsoLiteral = `<div id-corso='${corso.id}'>${corso.nome} <img id='delete' src='${require('../../icons/delete.svg')}'></div>`;

          this.domListaCorsi.innerHTML += divCorsoLiteral;
        //this.domViewCorsiTable.innerHTML += corsoLiteral;
        }
      } else {
        this.domListaCorsi.innerHTML += `<div id='no-corsi'> Non ci sono corsi sottoiscrivibili</div>`;
      //this.domViewCorsiTable.innerHTML = 'Si è verificato un errore';
      }
    } catch (ex) {
      this.domListaCorsi.innerHTML = `<p style='color:red'> Si è verificato un errore</p>`;
    }
  }

  async popolateListaIscritti(idCorso) {
    try {
      const spinner = this.domListaIscrittiTable.appendChild(new UGDCECSpinner());
      const response = await fetch(window.url + `/getSubscribedByCorso?idCorso=${idCorso}`);
      this.domListaIscrittiTable.removeChild(spinner);
      const iscritti = await response.json();

      if (iscritti.length > 0) {
        for (const iscritto of iscritti) {
          const iscrittiLiteral = `
          <tr>
            <td> ${iscritto.name} </th>
            <td> ${iscritto.cognome} </th>
            <td> ${iscritto.email} </th>
          </tr>`;

          this.domListaIscrittiTable.innerHTML += iscrittiLiteral;
        //this.domViewCorsiTable.innerHTML += corsoLiteral;
        }
      } else {
        //this.domListaIscrittiTable.innerHTML += '<center> Non sono presenti iscritti </center>';
        this.domListaIscritti.classList.add('hide');
      }
    } catch (ex) {
      this.domListaIscrittiTable.innerHTML += '<center> Si è verificato un errore </center>';
    }
  }
}

customElements.define('settings-viewer', SettingsViewer);
