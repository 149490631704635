import IscrizioneCorso from './iscrizione-corso.js';

const ugdcecCorsoTemplate = document.createElement('template');

ugdcecCorsoTemplate.innerHTML = `
  <div id='container'>
  </div>

  <style>
    * {
      box-sizing: border-box;
    }
    :host {
      width: 100%;
      display: block;
      overflow-x: hidden;
      height: 100%;
    }
    #container {
      background: white;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      display: flex;
      width: 200%;
      height: 100%;
      overflow-x: hidden;
    }

    info-corso, iscrizione-corso {
      height: 100%;
      transition: transform .5s ease;
    }

    info-corso {
      width: 50%;
    }

    iscrizione-corso {
      width: 50%;
    }
  </style>
`;
export default class UGDCECCorso extends HTMLElement {
  constructor(corso) {
    super();

    /* mi salvo i dati del corso da usare nel connectedCallback */
    this.datiCorso = corso;
    this.attachShadow({mode: 'open'});

    this.onBtnIscrivitiClick = this.onBtnIscrivitiClick.bind(this);
    this.onBtnGoBackHandler = this.onBtnGoBackHandler.bind(this);
    this.onProponiUnioneHandler = this.onProponiUnioneHandler.bind(this);
    this.iscrizioneUnioneClickedHandler = this.iscrizioneUnioneClickedHandler.bind(this);
  }

  connectedCallback() {
    this.shadowRoot.appendChild(ugdcecCorsoTemplate.content.cloneNode(true));

    this.domContainer = this.shadowRoot.querySelector('#container');
    this.infoCorso = this.domContainer.appendChild(document.createElement('info-corso'));

    this.infoCorso.nome = this.datiCorso.nome;
    this.infoCorso.desc = this.datiCorso.desc;
    this.infoCorso.scadenza = 'Scadenza: ' + this.datiCorso.scadenza;
    this.infoCorso.costo = this.datiCorso.costo;
    this.infoCorso.costoNonIscritti = this.datiCorso.costoNonIscritti;

    this.iscrizioneCorso = this.domContainer.appendChild(new IscrizioneCorso());

    this.iscrizioneCorso.proponiUnione = this.datiCorso.proponiIscrizioneUnione;
    this.iscrizioneCorso.name = this.datiCorso.nome;

    this.iscrizioneCorso.addEventListener('iscritto-unione-clicked', this.iscrizioneUnioneClickedHandler);

    if (!this.datiCorso.subscribed) {
      this.infoCorso.addEventListener('subscribe-clicked', this.onBtnIscrivitiClick);
      this.iscrizioneCorso.addEventListener('back', this.onBtnGoBackHandler);
      this.iscrizioneCorso.addEventListener('proponi-unione', this.onProponiUnioneHandler);
    } else {
      this.infoCorso.removeButtonIscriviti();
    }

    // Trigger setters
    this.id = this.datiCorso.id;
    this.name = this.datiCorso.nome;
    this.scadenza = this.datiCorso.scadenza;
    this.costo = this.datiCorso.costo;
    this.costoNonIscritti = this.datiCorso.costoNonIscritti;
    this.desc = this.datiCorso.desc;
  }

  set hide(bool) {
    this.setAttribute('hide', bool);
    if (bool) {
      this.style.opacity = 0;

      this.addEventListener(
          'transitionend',
          () => {
            if (bool) {
              this.style.display = 'none';
            }
          },
          {once: true}
      );
    } else {
      this.style.display = 'block';

      requestAnimationFrame(() => {
        this.style.opacity = 1;
      });
    }
  }

  get hide() {
    return this.getAttribute('hide');
  }

  onBtnGoBackHandler(evt) {
    this.dispatchEvent(new CustomEvent('back', {bubbles: true, detail: this}));

    this.iscrizioneCorso.restoreQuestion();
    this.infoCorso.translateIn();
    this.iscrizioneCorso.translateOut();
  }

  onProponiUnioneHandler() {
    this.dispatchEvent(new CustomEvent('proponi-unione', {bubbles: true}));
  }

  onBtnIscrivitiClick(evt) {
    this.dispatchEvent(new CustomEvent('subscribe-clicked', {bubbles: true, detail: this}));

    this.infoCorso.translateOut();
    this.iscrizioneCorso.translateIn();
  }

  iscrizioneUnioneClickedHandler(event) {
    const bIscrittoUnione = event.detail;
    this.dispatchEvent(new CustomEvent('iscritto-unione-clicked', {bubbles: true, detail: {corso: this, bIscrittoUnione}}));
  }
}

customElements.define('ugdcec-corso', UGDCECCorso);
